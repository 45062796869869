import React from 'react';
import { Navigate } from 'react-router-dom';
import { LoadingOverlay } from '@mantine/core';
import { useAuthContext } from 'contexts/AuthContext';
import { RouteEnum } from 'config/route.enum';

interface Props {
  children: JSX.Element;
}

export const ProtectedRoute = ({ children }: Props) => {
  const { isAuthenticated, isLoading } = useAuthContext();

  if (isLoading) {
    return <LoadingOverlay visible />;
  }

  if (isAuthenticated) {
    return children;
  }
  return <Navigate to={RouteEnum.LOGIN} replace />;
};
