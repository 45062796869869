import React, { ReactNode, useEffect, useState, createContext, useContext } from 'react';
import axios, { AxiosResponse, AxiosError } from 'axios';
import { ISignIn, ISignInError, signIn, viewAs } from 'services/auth';
import { isAuthenticated as checkIsAuthenticated } from 'utils/auth';
import { getNavigation } from 'services/navigation';
import { useNavigate } from 'react-router-dom';
import { RouteEnum } from 'config/route.enum';

interface AuthInterface {
  isAuthenticated: boolean;
  isLoading: boolean;
  isNavigationLoading: boolean;
  navigation: any;
  updateNavigation: any;
  setIsLoading: (isLoading: boolean) => void;
  setIsAuthenticated: (isAuthenticated: boolean) => void;
  signIn: (
    email: string,
    password: string,
    rememberMe: boolean,
    termsAccepted: boolean,
  ) => Promise<AxiosResponse<ISignIn>>;
  signOut: () => void;
  userType: string;
  setUserType: (userType: string) => void;
  showAdmin: boolean;
  setShowAdmin: (showAdmin: boolean) => void;
  userInbox: boolean;
  setUserInbox: (userInbox: boolean) => void;
  userName: string;
  setUserName: (userName: string) => void;
  viewAsUserName: string;
  viewAsUser: (userID: number) => Promise<void>;
  removeViewAsUser: () => Promise<void>;
}

const defaultValues: AuthInterface = {
  isAuthenticated: false,
  isLoading: true,
  isNavigationLoading: true,
  navigation: [],
  updateNavigation: () => {},
  setIsLoading: () => {},
  setIsAuthenticated: () => {},
  signIn: async () => {
    return axios.get<ISignIn>('/');
  },
  signOut: () => {},
  userType: '',
  setUserType: () => {},
  showAdmin: false,
  setShowAdmin: () => {},
  userInbox: false,
  setUserInbox: () => {},
  userName: '',
  setUserName: () => {},
  viewAsUserName: '',
  viewAsUser: async () => {},
  removeViewAsUser: async () => {},
};

export const AuthContext = createContext<AuthInterface>(defaultValues);

export const useAuthContext = () => useContext(AuthContext);

interface Props {
  children: ReactNode;
}

export const AuthContextProvider = ({ children }: Props) => {
  const [isLoading, setIsLoading] = useState(defaultValues.isLoading);
  const [isNavigationLoading, setIsNavigationLoading] = useState(defaultValues.isLoading);
  const [navigation, setNavigation] = useState(defaultValues.navigation);
  const [isAuthenticated, setIsAuthenticated] = useState(defaultValues.isAuthenticated);
  const [userName, setUserName] = useState(defaultValues.userName);
  const [userType, setUserType] = useState(defaultValues.userType);
  const [showAdmin, setShowAdmin] = useState(defaultValues.showAdmin);
  const [userInbox, setUserInbox] = useState(defaultValues.userInbox);
  const [viewAsUserName, setViewAsUserName] = useState(defaultValues.viewAsUserName);
  const navigate = useNavigate();

  useEffect(() => {
    async function checkAuthentication() {
      const auth = await checkIsAuthenticated();
      setIsAuthenticated(auth.isAuthenticated);
      setUserName(auth.viewAsUserName ?? auth.userName);
      setUserType(auth.userType);
      setShowAdmin(auth.showAdmin);
      setUserInbox(auth.userInbox);
      setIsLoading(false);
      auth.isAuthenticated && updateNavigation();
      auth.viewAsUserName && setViewAsUserName(auth.viewAsUserName);
    }
    checkAuthentication();
  }, []);

  // Will be later used for "use as" feature
  const updateNavigation = async () => {
    setIsNavigationLoading(true);
    const navigationResponse = await getNavigation();
    setIsNavigationLoading(false);
    setNavigation(navigationResponse.data.navigation);
  };

  const viewAsUser = async (userID: number) => {
    try {
      setIsLoading(true);
      const response = await viewAs(userID);
      setUserName(response.data.userName);
      setUserType(response.data.userType);
      setShowAdmin(response.data.showAdmin);
      setUserInbox(response.data.userInbox);
      setViewAsUserName(response.data.userName);
      updateNavigation();
      navigate(RouteEnum.DASHBOARD);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const removeViewAsUser = async () => {
    try {
      setIsLoading(true);
      const response = await viewAs(0, true);
      setUserName(response.data.userName);
      setUserType(response.data.userType);
      setShowAdmin(true);
      setUserInbox(true);
      setViewAsUserName('');
      updateNavigation();
      navigate('/manage-users');
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <AuthContext.Provider
      value={{
        isLoading,
        setIsLoading,
        isNavigationLoading,
        navigation,
        updateNavigation,
        isAuthenticated,
        setIsAuthenticated,
        signIn: async (email, password, rememberMe, termsAccepted) => {
          try {
            const response = await signIn(email, password, rememberMe, termsAccepted);
            const resData = response.data;
            if (!resData.mustAcceptTerms) {
              setIsAuthenticated(true);
              setUserName(response.data.userName);
              setUserType(response.data.userType);
              setShowAdmin(response.data.showAdmin);
              setUserInbox(response.data.userInbox);
              updateNavigation();
            }
            return response;
          } catch (error) {
            const typedError = error as AxiosError<ISignInError>;
            throw typedError.response;
          }
        },
        signOut: async () => {
          console.log('++ sign out');
        },
        userName,
        setUserName,
        userType,
        setUserType,
        showAdmin,
        setShowAdmin,
        userInbox,
        setUserInbox,
        viewAsUserName,
        viewAsUser,
        removeViewAsUser,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
