export enum RouteEnum {
  HOME = '/',
  LOGIN = '/login',
  DASHBOARD = '/dashboard',
  CONFLICT_OF_INTEREST = '/conflict-of-interest',
  CONFLICT_OF_INTEREST_DECLARATION = '/conflict-of-interest-declaration',
  ANNUAL_COMPLIANCE_ATTESTATION = '/annual-compliance-attestation',
  INITIAL_COMPLIANCE_ATTESTATION = '/initial-compliance-attestation',
  TRAINING_AND_COMPETENCE = '/annual-fitness-propriety-assessment',
  OUTSIDE_BUSINESS_INTEREST_AUTHORISATION = '/outside-business-interest-authorisation',
  COMPLIANCE_MANUAL_ACKNOWLEDGEMENT = '/compliance-manual-acknowledgement',
  GIFTS_AND_ENTERTAINMENT_AUTHORISATION = '/gifts-and-entertainment-authorisation',
  SUSPICIOUS_TRANSACTION_NOTIFICATION = '/suspicious-transaction-notification',
  PEP_IDENTIFICATION = '/pep-identification',
  PERSONAL_ACCOUNT_DEALING_AUTHORISATION = '/personal-account-dealing-authorisation',
  FIT_PROPER_CERTIFICATION = '/fit-proper-declaration',
  MANAGE_USERS = '/manage-users',
  EMPLOYEE_ONBOARDING = '/employee-onboarding',
  CLIENT_ONBOARDING = '/client-onboarding',
  USER_ONBOARDING = '/user-onboarding',
  EMPLOYEE_LIST = '/employee-list',
  EMPLOYEE = '/employee',
  DOCUMENTS = '/documents',
  ADD_USER = '/add-user',
  RESET_PASSWORD = '/reset-password/:code',
  EDIT_EMPLOYEE = '/edit-employee',
  EDIT_CLIENT = '/edit-client',
  BREACH_NOTIFICATION = '/breach-notification',
  COMPLAINTS = '/complaints',
  PRIVACY = '/privacy-policy',
  COOKIE = '/cookie-notice',
  TERMS = '/terms-condition',
}
