import { Anchor, Group } from '@mantine/core';
import { RouteEnum } from 'config/route.enum';
import { Link } from 'react-router-dom';
import { FooterWrapper } from './styles';

const links = [
  { link: RouteEnum.TERMS, label: 'Terms and Conditions' },
  { link: RouteEnum.PRIVACY, label: 'Privacy Policy' },
  { link: RouteEnum.COOKIE, label: 'Cookies Policy' },
];

const Footer = ({ paddingLeft, paddingTop, bg }: any) => {
  const items = links.map((link) => (
    <Link key={link.label} to={link.link} className="links">
      {link.label}
    </Link>
  ));
  return (
    <FooterWrapper>
      <div className="footer" style={{ paddingLeft: paddingLeft, paddingTop: paddingTop }}>
        <div className="inner">
          <Group>{items}</Group>
        </div>
      </div>
    </FooterWrapper>
  );
};

export default Footer;
