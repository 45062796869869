import React, { Suspense } from 'react';
import { Route, Routes } from 'react-router-dom';
import { LoadingOverlay } from '@mantine/core';
import { useAuthContext } from 'contexts/AuthContext';
import MainTemplate from 'templates/MainTemplate';
import { RouteEnum } from 'config/route.enum';
import { ProtectedRoute } from './ProtectedRoute';
import { RestrictedRoutes } from './RestrictedRoutes';

export const MainRoutes = () => {
  const { isLoading } = useAuthContext();

  if (isLoading) {
    return <LoadingOverlay visible />;
  }
  return (
    <Routes>
      {/* On this level goes every route that's public */}
      <Route
        path={RouteEnum.LOGIN}
        element={
          <Suspense fallback={<LoadingOverlay visible />}>
            {React.createElement(React.lazy(() => import(`pages/LoginPage`)))}
          </Suspense>
        }
      />
      <Route
        path={RouteEnum.RESET_PASSWORD}
        element={
          <Suspense fallback={<LoadingOverlay visible />}>
            {React.createElement(React.lazy(() => import(`pages/ResetPasswordPage`)))}
          </Suspense>
        }
      />
      <Route
        path={`${RouteEnum.EMPLOYEE_ONBOARDING}/new`}
        element={
          <Suspense fallback={<LoadingOverlay visible />}>
            {React.createElement(React.lazy(() => import(`pages/EmployeeOnboardingPage`)))}
          </Suspense>
        }
      />
      <Route
        path={`${RouteEnum.USER_ONBOARDING}/new`}
        element={
          <Suspense fallback={<LoadingOverlay visible />}>
            {React.createElement(React.lazy(() => import(`pages/UserOnboardingPage`)))}
          </Suspense>
        }
      />
      <Route
        path={RouteEnum.PRIVACY}
        element={
          <Suspense fallback={<LoadingOverlay visible />}>
            {React.createElement(React.lazy(() => import(`pages/PrivacyPolicy`)))}
          </Suspense>
        }
      />
      <Route
        path={RouteEnum.TERMS}
        element={
          <Suspense fallback={<LoadingOverlay visible />}>
            {React.createElement(React.lazy(() => import(`pages/TermsConditions`)))}
          </Suspense>
        }
      />
      <Route
        path={RouteEnum.COOKIE}
        element={
          <Suspense fallback={<LoadingOverlay visible />}>
            {React.createElement(React.lazy(() => import(`pages/CookieNotice`)))}
          </Suspense>
        }
      />
      <Route
        path="*"
        element={
          <ProtectedRoute>
            {/* Inside RestrictedRoutes are all routes, that require authentication */}
            <MainTemplate>
              <RestrictedRoutes />
            </MainTemplate>
          </ProtectedRoute>
        }
      />
    </Routes>
  );
};
