import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { MantineProvider } from '@mantine/core';
import { DatesProvider } from '@mantine/dates';
import { Notifications } from '@mantine/notifications';
import { MainRoutes } from 'routes';
import { AuthContextProvider } from 'contexts/AuthContext';
import 'assets/styles/styles.css';

const RootTemplate = () => (
  <BrowserRouter>
    <DatesProvider settings={{ locale: 'gb' }}>
      <MantineProvider
        withGlobalStyles
        withNormalizeCSS
        theme={{
          fontFamily: 'Inter, sans-serif',
          fontFamilyMonospace: 'Monaco, Courier, monospace',
          colors: {
            blue: [
              '#004BAD',
              '#5FCCDB',
              '#44CADC',
              '#2AC9DE',
              '#1AC2D9',
              '#11B7CD',
              '#004BAD',
              '#004BAD',
              '#128797',
              '#147885',
            ],
            sidebar: ['#326771'],
            navbar: ['#F0F7FF'],
          },
        }}
      >
        <Notifications />
        <AuthContextProvider>
          <MainRoutes />
        </AuthContextProvider>
      </MantineProvider>
    </DatesProvider>
  </BrowserRouter>
);

export default RootTemplate;
