import styled from 'styled-components';

export const SideBarWrapper = styled.div`
  .navbar {
    background-color: #f0f7ff;
    color: #384150;

    .nav-link {
      color: #384150;
      font-weight: 700;
      border-radius: 4px;
      margin-top: 16px;

      &:hover {
        background-color: white;
        color: #004bad;
        border-right: 4px solid #004bad;
        & svg path {
          fill: #004bad;
        }
      }

      &.active {
        background-color: white;
        color: #004bad;
        border-right: 4px solid #004bad;
        & svg path {
          fill: #004bad;
        }
      }
    }
  }
`;
