export const isAuthenticated = async () => {
  try {
    const response = await fetch(`${process.env.REACT_APP_SERVER_DOMAIN}/auth/authenticated`, {
      credentials: 'include', // Include cookies in the request
    });
    if (response.ok) {
      const data = await response.json();
      return data;
    }
    // User is not authenticated, return false or handle the error as needed
    return { isAuthenticated: false };
  } catch (error) {
    // An error occurred while fetching the data, handle the error as needed
    console.error('Error checking authentication:', error);
    return { isAuthenticated: false };
  }
};
