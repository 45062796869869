import axios from 'axios';

export const getNavigation = async () => {
  const response = await axios.get(`${process.env.REACT_APP_SERVER_DOMAIN}/navigation`, {
    headers: {
      'Content-Type': 'application/json',
    },
    withCredentials: true,
  });

  return response;
};
