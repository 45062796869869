import styled from 'styled-components';

export const FooterWrapper = styled.div`
  .footer {
    padding: 20px;
  }
  .inner {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .links {
    font-size: 0.875rem;
    text-decoration: none;
    color: #868e96;
    &:hover {
      text-decoration: underline;
    }
  }
`;
