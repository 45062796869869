import React, { Suspense } from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import { LoadingOverlay } from '@mantine/core';
import { RouteEnum } from 'config/route.enum';

// All routes, that require authentication

export const RestrictedRoutes = () => (
  <Routes>
    <Route path={RouteEnum.HOME} element={<Navigate to="/dashboard" replace />} />
    <Route
      path={RouteEnum.DASHBOARD}
      element={
        <Suspense fallback={<LoadingOverlay visible />}>
          {React.createElement(React.lazy(() => import(`pages/DashboardPage`)))}
        </Suspense>
      }
    />
    <Route
      path={RouteEnum.EMPLOYEE_LIST}
      element={
        <Suspense fallback={<LoadingOverlay visible />}>
          {React.createElement(React.lazy(() => import(`pages/EmployeeListPage`)))}
        </Suspense>
      }
    />
    <Route
      path={`${RouteEnum.EMPLOYEE}/:userID`}
      element={
        <Suspense fallback={<LoadingOverlay visible />}>
          {React.createElement(React.lazy(() => import(`pages/EmployeePage`)))}
        </Suspense>
      }
    />
    <Route
      path={RouteEnum.MANAGE_USERS}
      element={
        <Suspense fallback={<LoadingOverlay visible />}>
          {React.createElement(React.lazy(() => import(`pages/ManageUsersPage`)))}
        </Suspense>
      }
    />
    <Route
      path={`${RouteEnum.MANAGE_USERS}/:userID`}
      element={
        <Suspense fallback={<LoadingOverlay visible />}>
          {React.createElement(React.lazy(() => import(`pages/EditUserPage`)))}
        </Suspense>
      }
    />
    <Route
      path={RouteEnum.ADD_USER}
      element={
        <Suspense fallback={<LoadingOverlay visible />}>
          {React.createElement(React.lazy(() => import(`pages/AddUserPage`)))}
        </Suspense>
      }
    />
    <Route
      path="/user-roles"
      element={
        <Suspense fallback={<LoadingOverlay visible />}>
          {React.createElement(React.lazy(() => import(`pages/ManageUserRolesPage`)))}
        </Suspense>
      }
    />
    <Route
      path={`/user-roles/:roleID`}
      element={
        <Suspense fallback={<LoadingOverlay visible />}>
          {React.createElement(React.lazy(() => import(`pages/EditUserRolePage`)))}
        </Suspense>
      }
    />
    <Route
      path="/user-roles/add"
      element={
        <Suspense fallback={<LoadingOverlay visible />}>
          {React.createElement(React.lazy(() => import(`pages/AddUserRolePage`)))}
        </Suspense>
      }
    />
    <Route
      path={`${RouteEnum.EMPLOYEE_ONBOARDING}/:formID`}
      element={
        <Suspense fallback={<LoadingOverlay visible />}>
          {React.createElement(React.lazy(() => import(`pages/EmployeeOnboardingPage`)))}
        </Suspense>
      }
    />
    <Route
      path={RouteEnum.EDIT_EMPLOYEE}
      element={
        <Suspense fallback={<LoadingOverlay visible />}>
          {React.createElement(React.lazy(() => import(`pages/EmployeeOnboardingPage`)))}
        </Suspense>
      }
    />
    <Route
      path={RouteEnum.EDIT_CLIENT}
      element={
        <Suspense fallback={<LoadingOverlay visible />}>
          {React.createElement(React.lazy(() => import(`pages/ClientOnboardingPage`)))}
        </Suspense>
      }
    />
    <Route
      path={RouteEnum.CLIENT_ONBOARDING}
      element={
        <Suspense fallback={<LoadingOverlay visible />}>
          {React.createElement(React.lazy(() => import(`pages/ClientOnboardingPage`)))}
        </Suspense>
      }
    />
    <Route
      path={RouteEnum.DOCUMENTS}
      element={
        <Suspense fallback={<LoadingOverlay visible />}>
          {React.createElement(React.lazy(() => import(`pages/DocumentsPage`)))}
        </Suspense>
      }
    />
    <Route
      path={`${RouteEnum.DOCUMENTS}/:folderSlug?`}
      element={
        <Suspense fallback={<LoadingOverlay visible />}>
          {React.createElement(React.lazy(() => import('pages/DocumentsPage')))}
        </Suspense>
      }
    />

    <Route
      path="/compliance-forms"
      element={
        <Suspense fallback={<LoadingOverlay visible />}>
          {React.createElement(React.lazy(() => import('pages/ComplianceFormsPage')))}
        </Suspense>
      }
    />
    <Route
      path="/compliance-officers-forms"
      element={
        <Suspense fallback={<LoadingOverlay visible />}>
          {React.createElement(React.lazy(() => import('pages/ComplianceOfficerFormPage')))}
        </Suspense>
      }
    />

    <Route
      path="/compliance-registers"
      element={
        <Suspense fallback={<LoadingOverlay visible />}>
          {React.createElement(React.lazy(() => import('pages/ComplianceRegistersPage')))}
        </Suspense>
      }
    />

    {/* Compliance routes */}
    <Route
      path={RouteEnum.ANNUAL_COMPLIANCE_ATTESTATION}
      element={
        <Suspense fallback={<LoadingOverlay visible />}>
          {React.createElement(
            React.lazy(
              () =>
                import(
                  `pages/ComplianceForms/AnnualComplianceAttestation/AnnualComplianceAttestationPage`
                ),
            ),
          )}
        </Suspense>
      }
    />
    <Route
      path={`${RouteEnum.ANNUAL_COMPLIANCE_ATTESTATION}/new`}
      element={
        <Suspense fallback={<LoadingOverlay visible />}>
          {React.createElement(
            React.lazy(
              () =>
                import(
                  `pages/ComplianceForms/AnnualComplianceAttestation/AnnualComplianceAttestationForm`
                ),
            ),
          )}
        </Suspense>
      }
    />
    <Route
      path={`${RouteEnum.ANNUAL_COMPLIANCE_ATTESTATION}/:formID`}
      element={
        <Suspense fallback={<LoadingOverlay visible />}>
          {React.createElement(
            React.lazy(
              () =>
                import(
                  `pages/ComplianceForms/AnnualComplianceAttestation/AnnualComplianceAttestationForm`
                ),
            ),
          )}
        </Suspense>
      }
    />

    <Route
      path={RouteEnum.COMPLIANCE_MANUAL_ACKNOWLEDGEMENT}
      element={
        <Suspense fallback={<LoadingOverlay visible />}>
          {React.createElement(
            React.lazy(
              () =>
                import(
                  `pages/ComplianceForms/ComplianceManualAcknowledgement/ComplianceManualAcknowledgementPage`
                ),
            ),
          )}
        </Suspense>
      }
    />
    <Route
      path={`${RouteEnum.COMPLIANCE_MANUAL_ACKNOWLEDGEMENT}/new`}
      element={
        <Suspense fallback={<LoadingOverlay visible />}>
          {React.createElement(
            React.lazy(
              () =>
                import(
                  `pages/ComplianceForms/ComplianceManualAcknowledgement/ComplianceManualAcknowledgementForm`
                ),
            ),
          )}
        </Suspense>
      }
    />
    <Route
      path={`${RouteEnum.COMPLIANCE_MANUAL_ACKNOWLEDGEMENT}/:formID`}
      element={
        <Suspense fallback={<LoadingOverlay visible />}>
          {React.createElement(
            React.lazy(
              () =>
                import(
                  `pages/ComplianceForms/ComplianceManualAcknowledgement/ComplianceManualAcknowledgementForm`
                ),
            ),
          )}
        </Suspense>
      }
    />

    <Route
      path={RouteEnum.COMPLAINTS}
      element={
        <Suspense fallback={<LoadingOverlay visible />}>
          {React.createElement(
            React.lazy(() => import(`pages/ComplianceForms/Complaints/ComplaintsPage`)),
          )}
        </Suspense>
      }
    />
    <Route
      path={`${RouteEnum.COMPLAINTS}/new`}
      element={
        <Suspense fallback={<LoadingOverlay visible />}>
          {React.createElement(
            React.lazy(() => import(`pages/ComplianceForms/Complaints/ComplaintsForm`)),
          )}
        </Suspense>
      }
    />
    <Route
      path={`${RouteEnum.COMPLAINTS}/:formID`}
      element={
        <Suspense fallback={<LoadingOverlay visible />}>
          {React.createElement(
            React.lazy(() => import(`pages/ComplianceForms/Complaints/ComplaintsForm`)),
          )}
        </Suspense>
      }
    />

    <Route
      path={RouteEnum.BREACH_NOTIFICATION}
      element={
        <Suspense fallback={<LoadingOverlay visible />}>
          {React.createElement(
            React.lazy(
              () => import(`pages/ComplianceForms/BreachNotification/BreachNotificationPage`),
            ),
          )}
        </Suspense>
      }
    />
    <Route
      path={`${RouteEnum.BREACH_NOTIFICATION}/new`}
      element={
        <Suspense fallback={<LoadingOverlay visible />}>
          {React.createElement(
            React.lazy(
              () => import(`pages/ComplianceForms/BreachNotification/BreachNotificationForm`),
            ),
          )}
        </Suspense>
      }
    />
    <Route
      path={`${RouteEnum.BREACH_NOTIFICATION}/:formID`}
      element={
        <Suspense fallback={<LoadingOverlay visible />}>
          {React.createElement(
            React.lazy(
              () => import(`pages/ComplianceForms/BreachNotification/BreachNotificationForm`),
            ),
          )}
        </Suspense>
      }
    />

    <Route
      path={RouteEnum.CONFLICT_OF_INTEREST}
      element={
        <Suspense fallback={<LoadingOverlay visible />}>
          {React.createElement(
            React.lazy(
              () => import(`pages/ComplianceForms/ConflictOfInterest/ConflictOfInterestPage`),
            ),
          )}
        </Suspense>
      }
    />
    <Route
      path={`${RouteEnum.CONFLICT_OF_INTEREST}/new`}
      element={
        <Suspense fallback={<LoadingOverlay visible />}>
          {React.createElement(
            React.lazy(
              () => import(`pages/ComplianceForms/ConflictOfInterest/ConflictOfInterestForm`),
            ),
          )}
        </Suspense>
      }
    />
    <Route
      path={`${RouteEnum.CONFLICT_OF_INTEREST}/:formID`}
      element={
        <Suspense fallback={<LoadingOverlay visible />}>
          {React.createElement(
            React.lazy(
              () => import(`pages/ComplianceForms/ConflictOfInterest/ConflictOfInterestForm`),
            ),
          )}
        </Suspense>
      }
    />

    <Route
      path={RouteEnum.CONFLICT_OF_INTEREST_DECLARATION}
      element={
        <Suspense fallback={<LoadingOverlay visible />}>
          {React.createElement(
            React.lazy(
              () =>
                import(
                  `pages/ComplianceForms/ConflictOfInterestDeclaration/ConflictOfInterestDeclarationPage`
                ),
            ),
          )}
        </Suspense>
      }
    />
    <Route
      path={`${RouteEnum.CONFLICT_OF_INTEREST_DECLARATION}/new`}
      element={
        <Suspense fallback={<LoadingOverlay visible />}>
          {React.createElement(
            React.lazy(
              () =>
                import(
                  `pages/ComplianceForms/ConflictOfInterestDeclaration/ConflictOfInterestDeclarationForm`
                ),
            ),
          )}
        </Suspense>
      }
    />
    <Route
      path={`${RouteEnum.CONFLICT_OF_INTEREST_DECLARATION}/:formID`}
      element={
        <Suspense fallback={<LoadingOverlay visible />}>
          {React.createElement(
            React.lazy(
              () =>
                import(
                  `pages/ComplianceForms/ConflictOfInterestDeclaration/ConflictOfInterestDeclarationForm`
                ),
            ),
          )}
        </Suspense>
      }
    />

    <Route
      path={RouteEnum.FIT_PROPER_CERTIFICATION}
      element={
        <Suspense fallback={<LoadingOverlay visible />}>
          {React.createElement(
            React.lazy(
              () =>
                import(`pages/ComplianceForms/FitProperCertification/FitProperCertificationPage`),
            ),
          )}
        </Suspense>
      }
    />
    <Route
      path={`${RouteEnum.FIT_PROPER_CERTIFICATION}/new`}
      element={
        <Suspense fallback={<LoadingOverlay visible />}>
          {React.createElement(
            React.lazy(
              () =>
                import(`pages/ComplianceForms/FitProperCertification/FitProperCertificationForm`),
            ),
          )}
        </Suspense>
      }
    />
    <Route
      path={`${RouteEnum.FIT_PROPER_CERTIFICATION}/:formID`}
      element={
        <Suspense fallback={<LoadingOverlay visible />}>
          {React.createElement(
            React.lazy(
              () =>
                import(`pages/ComplianceForms/FitProperCertification/FitProperCertificationForm`),
            ),
          )}
        </Suspense>
      }
    />

    <Route
      path={RouteEnum.GIFTS_AND_ENTERTAINMENT_AUTHORISATION}
      element={
        <Suspense fallback={<LoadingOverlay visible />}>
          {React.createElement(
            React.lazy(
              () =>
                import(
                  `pages/ComplianceForms/GiftsAndEntertainmentAuthorisation/GiftsAndEntertainmentAuthorisationPage`
                ),
            ),
          )}
        </Suspense>
      }
    />
    <Route
      path={`${RouteEnum.GIFTS_AND_ENTERTAINMENT_AUTHORISATION}/new`}
      element={
        <Suspense fallback={<LoadingOverlay visible />}>
          {React.createElement(
            React.lazy(
              () =>
                import(
                  `pages/ComplianceForms/GiftsAndEntertainmentAuthorisation/GiftsAndEntertainmentAuthorisationForm`
                ),
            ),
          )}
        </Suspense>
      }
    />
    <Route
      path={`${RouteEnum.GIFTS_AND_ENTERTAINMENT_AUTHORISATION}/:formID`}
      element={
        <Suspense fallback={<LoadingOverlay visible />}>
          {React.createElement(
            React.lazy(
              () =>
                import(
                  `pages/ComplianceForms/GiftsAndEntertainmentAuthorisation/GiftsAndEntertainmentAuthorisationForm`
                ),
            ),
          )}
        </Suspense>
      }
    />

    <Route
      path={RouteEnum.INITIAL_COMPLIANCE_ATTESTATION}
      element={
        <Suspense fallback={<LoadingOverlay visible />}>
          {React.createElement(
            React.lazy(
              () =>
                import(
                  `pages/ComplianceForms/InitialComplianceAttestation/InitialComplianceAttestationPage`
                ),
            ),
          )}
        </Suspense>
      }
    />
    <Route
      path={`${RouteEnum.INITIAL_COMPLIANCE_ATTESTATION}/new`}
      element={
        <Suspense fallback={<LoadingOverlay visible />}>
          {React.createElement(
            React.lazy(
              () =>
                import(
                  `pages/ComplianceForms/InitialComplianceAttestation/InitialComplianceAttestationForm`
                ),
            ),
          )}
        </Suspense>
      }
    />
    <Route
      path={`${RouteEnum.INITIAL_COMPLIANCE_ATTESTATION}/:formID`}
      element={
        <Suspense fallback={<LoadingOverlay visible />}>
          {React.createElement(
            React.lazy(
              () =>
                import(
                  `pages/ComplianceForms/InitialComplianceAttestation/InitialComplianceAttestationForm`
                ),
            ),
          )}
        </Suspense>
      }
    />

    <Route
      path={RouteEnum.OUTSIDE_BUSINESS_INTEREST_AUTHORISATION}
      element={
        <Suspense fallback={<LoadingOverlay visible />}>
          {React.createElement(
            React.lazy(
              () =>
                import(
                  `pages/ComplianceForms/OutsideBusinessInterestDeclaration/OutsideBusinessInterestDeclarationPage`
                ),
            ),
          )}
        </Suspense>
      }
    />
    <Route
      path={`${RouteEnum.OUTSIDE_BUSINESS_INTEREST_AUTHORISATION}/new`}
      element={
        <Suspense fallback={<LoadingOverlay visible />}>
          {React.createElement(
            React.lazy(
              () =>
                import(
                  `pages/ComplianceForms/OutsideBusinessInterestDeclaration/OutsideBusinessInterestDeclarationForm`
                ),
            ),
          )}
        </Suspense>
      }
    />
    <Route
      path={`${RouteEnum.OUTSIDE_BUSINESS_INTEREST_AUTHORISATION}/:formID`}
      element={
        <Suspense fallback={<LoadingOverlay visible />}>
          {React.createElement(
            React.lazy(
              () =>
                import(
                  `pages/ComplianceForms/OutsideBusinessInterestDeclaration/OutsideBusinessInterestDeclarationForm`
                ),
            ),
          )}
        </Suspense>
      }
    />

    <Route
      path={RouteEnum.PEP_IDENTIFICATION}
      element={
        <Suspense fallback={<LoadingOverlay visible />}>
          {React.createElement(
            React.lazy(
              () => import(`pages/ComplianceForms/PEPIdentification/PEPIdentificationPage`),
            ),
          )}
        </Suspense>
      }
    />
    <Route
      path={`${RouteEnum.PEP_IDENTIFICATION}/new`}
      element={
        <Suspense fallback={<LoadingOverlay visible />}>
          {React.createElement(
            React.lazy(
              () => import(`pages/ComplianceForms/PEPIdentification/PEPIdentificationForm`),
            ),
          )}
        </Suspense>
      }
    />
    <Route
      path={`${RouteEnum.PEP_IDENTIFICATION}/:formID`}
      element={
        <Suspense fallback={<LoadingOverlay visible />}>
          {React.createElement(
            React.lazy(
              () => import(`pages/ComplianceForms/PEPIdentification/PEPIdentificationForm`),
            ),
          )}
        </Suspense>
      }
    />

    <Route
      path={RouteEnum.PERSONAL_ACCOUNT_DEALING_AUTHORISATION}
      element={
        <Suspense fallback={<LoadingOverlay visible />}>
          {React.createElement(
            React.lazy(
              () =>
                import(
                  `pages/ComplianceForms/PersonalAccountDealingAuthorisation/PersonalAccountDealingAuthorisationPage`
                ),
            ),
          )}
        </Suspense>
      }
    />
    <Route
      path={`${RouteEnum.PERSONAL_ACCOUNT_DEALING_AUTHORISATION}/new`}
      element={
        <Suspense fallback={<LoadingOverlay visible />}>
          {React.createElement(
            React.lazy(
              () =>
                import(
                  `pages/ComplianceForms/PersonalAccountDealingAuthorisation/PersonalAccountDealingAuthorisationForm`
                ),
            ),
          )}
        </Suspense>
      }
    />
    <Route
      path={`${RouteEnum.PERSONAL_ACCOUNT_DEALING_AUTHORISATION}/:formID`}
      element={
        <Suspense fallback={<LoadingOverlay visible />}>
          {React.createElement(
            React.lazy(
              () =>
                import(
                  `pages/ComplianceForms/PersonalAccountDealingAuthorisation/PersonalAccountDealingAuthorisationForm`
                ),
            ),
          )}
        </Suspense>
      }
    />

    <Route
      path={RouteEnum.SUSPICIOUS_TRANSACTION_NOTIFICATION}
      element={
        <Suspense fallback={<LoadingOverlay visible />}>
          {React.createElement(
            React.lazy(
              () =>
                import(
                  `pages/ComplianceForms/SuspiciousTransactionNotification/SuspiciousTransactionNotificationPage`
                ),
            ),
          )}
        </Suspense>
      }
    />
    <Route
      path={`${RouteEnum.SUSPICIOUS_TRANSACTION_NOTIFICATION}/new`}
      element={
        <Suspense fallback={<LoadingOverlay visible />}>
          {React.createElement(
            React.lazy(
              () =>
                import(
                  `pages/ComplianceForms/SuspiciousTransactionNotification/SuspiciousTransactionNotificationForm`
                ),
            ),
          )}
        </Suspense>
      }
    />
    <Route
      path={`${RouteEnum.SUSPICIOUS_TRANSACTION_NOTIFICATION}/:formID`}
      element={
        <Suspense fallback={<LoadingOverlay visible />}>
          {React.createElement(
            React.lazy(
              () =>
                import(
                  `pages/ComplianceForms/SuspiciousTransactionNotification/SuspiciousTransactionNotificationForm`
                ),
            ),
          )}
        </Suspense>
      }
    />

    <Route
      path={RouteEnum.TRAINING_AND_COMPETENCE}
      element={
        <Suspense fallback={<LoadingOverlay visible />}>
          {React.createElement(
            React.lazy(
              () => import(`pages/ComplianceForms/TrainingAndCompetence/TrainingAndCompetencePage`),
            ),
          )}
        </Suspense>
      }
    />
    <Route
      path={`${RouteEnum.TRAINING_AND_COMPETENCE}/new`}
      element={
        <Suspense fallback={<LoadingOverlay visible />}>
          {React.createElement(
            React.lazy(
              () => import(`pages/ComplianceForms/TrainingAndCompetence/TrainingAndCompetenceForm`),
            ),
          )}
        </Suspense>
      }
    />
    <Route
      path={`${RouteEnum.TRAINING_AND_COMPETENCE}/:formID`}
      element={
        <Suspense fallback={<LoadingOverlay visible />}>
          {React.createElement(
            React.lazy(
              () => import(`pages/ComplianceForms/TrainingAndCompetence/TrainingAndCompetenceForm`),
            ),
          )}
        </Suspense>
      }
    />
    <Route
      path="/email-inbox"
      element={
        <Suspense fallback={<LoadingOverlay visible />}>
          {React.createElement(React.lazy(() => import(`pages/EmailInboxPage`)))}
        </Suspense>
      }
    />
  </Routes>
);
