import axios from 'axios';

export interface ISignIn {
  message: string;
  userName: string;
  userType: string;
  showAdmin: boolean;
  userInbox: boolean;
  mustAcceptTerms?: boolean;
}

export interface ISignInError {
  error: string;
  errorField: 'email' | 'password';
}

export const signIn = async (
  email: string,
  password: string,
  rememberMe: boolean,
  termsAccepted: boolean,
) => {
  const response: any = await axios.post<ISignIn>(
    `${process.env.REACT_APP_SERVER_DOMAIN}/auth/login`,
    {
      email,
      password,
      rememberMe,
      termsAccepted,
    },
    {
      headers: {
        'Content-Type': 'application/json',
      },
      withCredentials: true,
    },
  );
  localStorage.setItem("user", response.data.userName)
  return response;
};

export const viewAs = async (userID: number, remove = false) => {
  const response = await axios.post<ISignIn>(
    `${process.env.REACT_APP_SERVER_DOMAIN}/auth/view-as`,
    {
      userID,
      remove,
    },
    {
      headers: {
        'Content-Type': 'application/json',
      },
      withCredentials: true,
    },
  );

  return response;
};
