import React, { useRef, useState } from 'react';
import {
  LoadingOverlay,
  NavLink,
  Navbar,
  ScrollArea,
  Notification,
  createStyles,
} from '@mantine/core';

import { NavLink as RouterNavLink, useNavigate } from 'react-router-dom';
import { useAuthContext } from 'contexts/AuthContext';
import { ReactComponent as ClientEngagementAndOnboardingIcon } from 'assets/svg/navigation/Client Engagement and Onboarding.svg';
import { ReactComponent as ComplianceFormsIcon } from 'assets/svg/navigation/Compliance Forms.svg';
import { ReactComponent as ComplianceRegistersIcon } from 'assets/svg/navigation/Compliance Registers.svg';
import { ReactComponent as DashboardIcon } from 'assets/svg/navigation/Dashboard.svg';
import { ReactComponent as DataProtectionIcon } from 'assets/svg/navigation/Data Protection.svg';
import { ReactComponent as EntityGovernanceAndAdministrationIcon } from 'assets/svg/navigation/Entity Governance and Administration.svg';
import { ReactComponent as HumanResourcesIcon } from 'assets/svg/navigation/Human Resources.svg';
import { ReactComponent as PoliciesAndProceduresIcon } from 'assets/svg/navigation/Policies and Procedures.svg';
import { ReactComponent as ProjectMaterialsIcon } from 'assets/svg/navigation/Project Materials.svg';
import { ReactComponent as StaffTrainingIcon } from 'assets/svg/navigation/Staff Training.svg';
import { ReactComponent as TemplatesAndResourcesIcon } from 'assets/svg/navigation/Templates and Resources.svg';
import { SideBarWrapper } from './styles';

const getIconComponent = (label) => {
  const iconMap = {
    'Client Engagement': ClientEngagementAndOnboardingIcon,
    'Compliance Forms': ComplianceFormsIcon, // Assuming "UK Compliance Forms" is for "Compliance Forms.svg"
    'Compliance Registers': ComplianceRegistersIcon, // Assuming "UK Compliance Registers" is for "Compliance Registers.svg"
    Dashboard: DashboardIcon,
    'Data Protection': DataProtectionIcon,
    'Entity Management': EntityGovernanceAndAdministrationIcon,
    'Human Resources': HumanResourcesIcon,
    'Compliance Policies': PoliciesAndProceduresIcon,
    'Project Materials': ProjectMaterialsIcon,
    'Staff Training': StaffTrainingIcon,
    'Templates and Resources': TemplatesAndResourcesIcon,
    'HR Policies': HumanResourcesIcon,
    'Compliance Officer Forms': ComplianceFormsIcon,
  };

  return iconMap[label] || ComplianceFormsIcon;
};

const Sidebar = () => {
  const [opened, setOpened] = useState('');
  const { isNavigationLoading, navigation, viewAsUserName, removeViewAsUser } = useAuthContext();
  const [lastClickedLink, setLastClickedLink] = useState(null);
  const navigate = useNavigate();

  const handleLinkClick = (e: any, link: any) => {
    if (!(e.target instanceof SVGElement)) {
      navigate(link); // Navigate to the link if not SVG
    }
  };

  const handleChange = (isOpen: any, label: any) => {
    if (label === lastClickedLink) {
      return; // Do not toggle expansion if this link was the last clicked
    }

    if (isOpen) {
      setOpened(label);
    } else {
      setOpened('');
    }
  };

  return (
    <SideBarWrapper>
      <Navbar p="xs" hiddenBreakpoint="sm" className="navbar" width={{ sm: 200, lg: 300 }}>
        {isNavigationLoading ? (
          //  @ts-ignore
          <LoadingOverlay zIndex={1000} visible overlayprops={{ radius: 'sm', blur: 2 }} />
        ) : (
          <Navbar.Section grow component={ScrollArea} mx="-xs" px="xs">
            {navigation.map((item: any) => {
              if ('link' in item && item.links) {
                // This is an item that has both a link and child links
                return (
                  <NavLink
                    key={item.label}
                    component={RouterNavLink}
                    to={item.link}
                    className="nav-link"
                    label={item.label}
                    childrenOffset={28}
                    opened={opened === item.label}
                    onClick={(e) => handleLinkClick(e, item.link)}
                    onChange={(isOpen) => handleChange(isOpen, item.label)}
                  >
                    {item.links.map((nestedLink: any) => (
                      <NavLink
                        key={nestedLink.label}
                        component={RouterNavLink}
                        to={nestedLink.link}
                        onClick={(e) => handleLinkClick(e, nestedLink.link)}
                        label={nestedLink.label}
                        className="nav-link"
                      />
                    ))}
                  </NavLink>
                );
              } else if ('link' in item) {
                return (
                  <NavLink
                    key={item.label}
                    component={RouterNavLink}
                    icon={React.createElement(getIconComponent(item.label), {
                      style: { width: 24, height: 24 },
                    })}
                    to={item.link}
                    onClick={(e) => handleLinkClick(e, item.link)}
                    className="nav-link"
                    label={item.label}
                  />
                );
              } else if (item.links) {
                return (
                  <NavLink
                    key={item.label}
                    component={'button'}
                    className="nav-link"
                    label={item.label}
                    childrenOffset={28}
                    opened={opened === item.label}
                    onChange={(isOpen) => {
                      if (isOpen) {
                        setOpened(item.label);
                      } else {
                        setOpened('');
                      }
                    }}
                  >
                    {item.links.map((nestedLink: any) => (
                      <NavLink
                        key={nestedLink.label}
                        component={RouterNavLink}
                        to={nestedLink.link}
                        onClick={(e) => handleLinkClick(e, nestedLink.link)}
                        label={nestedLink.label}
                        className="nav-link"
                      />
                    ))}
                  </NavLink>
                );
              }
              return null; // For safety in case there's an item that isn't one of the expected types
            })}

            {viewAsUserName && (
              <Notification
                mt="lg"
                title={`Viewing as: ${viewAsUserName}`}
                onClose={() => {
                  removeViewAsUser();
                }}
              />
            )}
          </Navbar.Section>
        )}
      </Navbar>
    </SideBarWrapper>
  );
};

export default Sidebar;
