import styled from 'styled-components';

export const MainTemplateWrapper = styled.div`
  input {
    border-radius: 8px;
  }
  .custom-button {
    padding: 12px 62px;
    width: auto !important;
    border-radius: 8px;
  }
  .sub-heading {
    font-size: 14px;
    font-weight: 600;
  }
  [aria-label='Go to next page'] {
    background-color: #004bad;
    color: white;
  }
  [aria-label='Go to previous page'] {
    background-color: #004bad;
    color: white;
  }
  [aria-label='Go to next page']:disabled {
    background-color: #f9fafb;
    color: #ced4da;
  }
  [aria-label='Go to previous page']:disabled {
    background-color: #f9fafb;
    color: #ced4da;
  }
  [aria-label='Toggle full screen'],
  [aria-label='Show/Hide filters'] {
    margin-top: 10px;
  }
  .mantine-RadioGroup-error {
    margin-top: 10px;
  }
`;

export const FormAllPageWrapper = styled.div`
  thead > tr {
    background: linear-gradient(to right, #004bad 0%, #14c7c0 100%);
    th {
      color: white;
      svg path {
        color: white;
      }
      .mantine-TableHeadCell-Content {
        justify-content: center !important;
      }
    }
  }
  td {
    text-align: center;
  }
`;
export const FormPageTemplateWrapper = styled.div`
  [data-with-border='true'] {
    border-radius: 12px;
    border: 1px solid #eaecf0;
    box-shadow: none;
  }
  thead > tr {
    border-top: 1px solid #eaecf0;
    background: #f9fafb;
  }
`;
